import React from 'react'
import { classNames } from '@madup-inc/utils'
import { oneOf } from '../utils'

interface IProps {
  type: string
  size?: number
  style?: any
  className?: string
  onClick?: any
}

const imageServer = oneOf(
  [
    [
      // docz 로컬환경
      process.env.NODE_ENV === 'development' &&
        typeof window !== 'undefined' &&
        window.location.host === 'localhost:15000',
      '',
    ],
    [
      // docz 개발환경
      typeof window !== 'undefined' &&
        window.location.host === 'dev.design.lever.me',
      '',
    ],
  ],
  // docz 운영환경 or 외부 개발환경
  'https://design.lever.me',
)

export const Icon = ({ type, size, style, onClick, className }: IProps) => {
  return (
    <img
      src={imageServer + `/public/images/icons/icon-${type}.svg`}
      alt={type}
      style={{ width: size, ...style }}
      onClick={onClick}
      className={classNames('mad-icon', className)}
    />
  )
}
