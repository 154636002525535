export const oneOf = (
  items: Array<[boolean, any]>,
  defaultValue?: any,
): any => {
  const matched = items.find((item) => item[0])
  return matched ? matched[1] : defaultValue
}

export const presetColors = [
  'red',
  'volcano',
  'orange',
  'gold',
  'lime',
  'green',
  'cyan',
  'blue',
  'geekblue',
  'purple',
  'magenta',
]
